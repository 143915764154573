import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { NAV_LINKS } from "../../constants/navigation";
import { Link as MuiLink } from "@material-ui/core";
import { Link } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import cx from "classnames";
import {useWindowScroll} from "../../utils/useWindowScroll";
import { LINKS } from "../../constants/links";

const useStyles = makeStyles((theme) => ({
  container :{
    display: "flex",
    position: "relative",
    margin: 0,
  },
  linkContainer :{
    display: "flex",
    position: "relative",
  },
  navLink: {
    cursor: "pointer",
    lineHeight: 1.5,
    fontSize: '.825rem',
    fontWeight: 500,
    padding: theme.spacing(0.5, 0),
    borderBottom: "1px solid transparent",
    margin: theme.spacing(0, 2),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  navLinkOpen: {
    color: theme.palette.text.primary,
  },
  link: {
    color: '#5d5d5d',
  },
  navLinkContainer: {
    display: "flex",
  },
  popoverRoot: {
    backgroundColor: 'white',
    maxWidth: 1000
  },
}));

const getMenuContext = () => {
  let navListWithoutShop = [];
  NAV_LINKS.forEach(link => {
    const { label, ...rest } = link;
    if(label !== "Shop"){
      navListWithoutShop.push({label, open: false, ...rest})
    }
  });
  return navListWithoutShop;
}

const TopMenu = () => {
  const classes = useStyles();
  const sectionRef = useRef(null);
  const [content, setContent] = useState(getMenuContext());
  const [closeItem, setCloseItem] = useState(true);
  const [closeTimeout, setCloseTimeout] = useState();
  const scroll = useWindowScroll()

  const setNewTimerForClose = () => {
    const newContext = content.map(item => {
      item.open = false
      return item
    })
    setContent(newContext)
  }

  useEffect(() => {
    !closeItem && clearTimeout(closeTimeout)
    const timerId = closeItem && setTimeout(setNewTimerForClose,800, closeItem);
    setCloseTimeout(timerId)
  }, [closeItem]);

  useEffect(() => {
    setNewTimerForClose()
  }, [scroll]);

  const  openMenu = e => {
    e.stopPropagation();
    e.preventDefault();
    setCloseItem(false)
    const newContext = content.map(item => {
      item.open = (item.label === e.currentTarget.id)
      return item
    })
    setContent(newContext)
  }

  const closeMenu = () => {
    setCloseItem(true)
  }

  const onClose = () => {
    setNewTimerForClose()
  }

  const setSectionRef = useCallback((node) => {
    sectionRef.current = node;
  }, [])

  return (
    <div className={classes.container} ref={setSectionRef}>
          <div className={classes.linkContainer}>{content.map(link => {
            const { nodes, label, to, hasSections, open, width } = link
            return <div key={to} className={classes.navLinkContainer} id={label} onClick={to.startsWith('/') && openMenu}>
              {nodes ?
                <RichTooltip
                  key={to}
                  content={nodes}
                  open={open}
                  placement="bottom"
                  onClose={closeMenu}
                  onOpen={openMenu}
                  hasSections={hasSections}
                  label={label}
                  width={width}
                >
                  <Typography
                    align="center"
                    className={cx(classes.navLink, {
                      [classes.navLinkOpen]: open,
                    })}
                    mt="auto"
                    aria-describedby={'popover'}
                  >
                    {label}
                  </Typography>
                </RichTooltip>
                :
                <MuiLink
                  component={to.startsWith('/') ? Link : 'a'}
                  to={to.startsWith('/') ? to : ''}
                  href={!to.startsWith('/') ? to : ''}
                  className={cx(classes.link, classes.navLink)}
                  color={"textPrimary"}
                  underline="none"
                  onMouseEnter={onClose}
                >
                  {label}
                </MuiLink>
              }
            </div>
          })}
          </div>
    </div>
  );
};

TopMenu.propTypes = {
  path: PropTypes.string,
};

export default TopMenu;






const useStylesRichTooltip = makeStyles((theme) => {
  const color = theme.palette.background.paper;
  return {
    footerTitle: {
      color:theme.palette.primary.main,
      fontWeight: 600,
      cursor: "pointer",
      lineHeight: 1.563,
      fontSize: '.8rem',
      textDecoration: 'none',
      "&:hover": {
        textDecoration: 'underline',
      },
    },
    footerContainer: {
      backgroundColor: theme.palette.background.lightGrey,
      padding: '10px 35px',
      display: "flex",
      justifyContent: "center",
      borderRadius: '0 0 10px 10px',
    },
    contentContainerSections: {
      display: "grid",
      grid: ' auto / 1fr',
      gridAutoFlow: 'row',
      margin: '30px 0',
      padding: '0 30px',
      minWidth: 420,
},
    borderRight: {
      borderRight: '1px solid #DDDDDD'
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      padding: 30,
      minWidth: 100,
    },
    popoverRoot: {
      backgroundColor: color,
      borderRadius: 10,
    },
    mainContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    linkTitle: {
      cursor: "pointer",
      lineHeight: 1.563,
      fontSize: '.825rem',
      color: theme.palette.text.secondary,
      fontWeight: 400,
      marginRight: 10,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    sectionTitle: {
      lineHeight: 1,
      marginBottom: 20,
      fontSize: '.65rem',
      color: theme.palette.primary.main,
      fontWeight: 500,
      textTransform: "uppercase",
      gridColumn: 'span 2',
    },
    shadow: {
      boxShadow: '0px 0px 16px -4px rgba(0,0,0,0.37);',
    },
    popper: {
      zIndex: 2000,
      marginLeft: 100,
      marginTop: 15,
      width: "max-content",

      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "0 100%"
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "100% 0"
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "100% 100%"
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "0 0"
        }
      }
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
    arrow: {
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",
      color,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: theme.shadows[1],
        backgroundColor: "currentColor",
        transform: "rotate(45deg)"
      }
    }
  };
});

export const RichTooltip = ({
                       placement = "top",
                       arrow = true,
                       open,
                       onClose = () => {},
                       content,
                       children,
                       hasSections,
                       onOpen,
                       label,
                       width,
                     }) => {
  const classes = useStylesRichTooltip();
  const [arrowRef, setArrowRef] = React.useState(null);
  const [childNode, setChildNode] = React.useState(null);
  let sections;
  hasSections && (sections = [...content])

  return (
    <div role="presentation"
         id={label}
         onMouseEnter={onOpen}
         onMouseLeave={onClose}
         style={{
           maxWidth: width,
           width: width
         }}
    >
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        disablePortal={true}
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window"
          },
          arrow: {
            enabled: arrow,
            element: arrowRef
          }
        }}
      >
        <Paper classes={{
                 root: classes.popoverRoot,
                 elevation1: classes.shadow,
               }}>
          <span className={classes.arrow} ref={setArrowRef} />
          <Box className={classes.content}>
            {
              hasSections ?
                <div className={classes.mainContainer}>
                  <div className={cx(classes.contentContainerSections, classes.borderRight)}>
                    {
                      sections.splice(0,8).map(({label, to}) => (
                          <>{to ? <MuiLink
                                key={to}
                                component={Link}
                                to={to}
                                className={classes.linkTitle}
                                underline="none"
                              >
                                {label}
                              </MuiLink>
                              :
                              <div key={label}
                                   className={classes.sectionTitle}
                              >
                                {label}
                              </div>
                          }
                      </>
                      ))
                    }
                  </div>
                  <div className={classes.contentContainerSections}>
                    {
                      sections.map(({label, to}) => (
                        <>
                          {to ? <MuiLink
                              key={to}
                              component={Link}
                              to={to}
                              className={classes.linkTitle}
                              underline="none"
                            >
                              {label}
                            </MuiLink>
                            :
                            <div key={label}
                                 className={classes.sectionTitle}
                            >
                              {label}
                            </div>
                          }
                        </>
                      ))
                    }
                  </div>
                </div>
                :
                <div className={classes.contentContainer}>
                  {
                    content.map(({label, to}) => (
                      <MuiLink
                        onClick={() => {
                          !to.startsWith('/') && window && window.location.replace(to);
                        }}
                        key={to}
                        component={to.startsWith('/') ? Link : 'a'}
                        to={to.startsWith('/') ? to : ''}
                        href={!to.startsWith('/') ? to : ''}
                        className={classes.linkTitle}
                        underline="none"
                      >
                        {label}
                      </MuiLink>
                    ))
                  }
                </div>
            }
            <div className={classes.footerContainer}>
              <MuiLink
                component={Link}
                className={classes.footerTitle}
                color="secondary"
                underline="none"
                to={LINKS.contact + '?reason=request_a_demo&subject=Request+a+Slate+Pages+Demo'}
              >
                Request a Demo
              </MuiLink>
            </div>
          </Box>
        </Paper>
      </Popper>
    </div>
  );
};
