import React from "react";
import PropTypes from "prop-types";
import { Typography as MuiTypography } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import cx from "classnames";

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 600,
    fontSize: '1rem',
    lineHeight: 1.563,
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Subtitle = ({ children, className, align = "center", ...rest }) => {
  const classes = useStyles();
  return (
    <Typography
      align={align}
      color="textSecondary"
      className={cx(
        classes.root,
        {
          [classes.center]: align === "center",
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Typography>
  );
};

Subtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default Subtitle;
