import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Button as MuiButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import cx from "classnames";

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '.7rem',
    fontWeight: 500,
    borderRadius: theme.spacing(3),
    letterSpacing: 2,
    padding: '7px 23px',
    boxShadow: '1px 13px 14px -5px rgba(12,100,206,0.32)',
  },
}));

const CustomButton = ({ children, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Button px={3} className={cx(classes.button, className)} {...rest}>
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default CustomButton;
