import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { CssBaseline, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import Header from "./header";
import Footer from "./footer";
import Seo from "./seo";
import theme from "../../utils/theme";
import { gsap } from "gsap";

const useStyles = makeStyles(() => ({
  main: {
    maxWidth: '100vw',
    width: '100%',
    minHeight: "100vh",
    paddingTop: 140, // fixed header height + padding
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      paddingTop: 40,
    },

    [theme.breakpoints.down(850)]: {
      maxWidth: '100vw',
    },
  },
}));

const Layout = ({
                  children,
                  HeaderProps,
                  SeoProps,
                  useSpecialLandingPageStyle = false,
                }) => {
  const classes = useStyles();
  const itemRef = useRef(null);
  const sectionRef = useRef(null);
  const setSectionRef = useCallback((node) => {

    sectionRef.current = node;
    if (node) {
      gsap
        .timeline({
          defaults: { duration: 1 },
          scrollTrigger: {
            trigger: node,
            scrub: 0.3,
            start: "0px top",
            end: `+=200`,
          },
          autoAlpha: 0,
        })
        .add("start")
        .to(itemRef.current, {
          height: 64,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div ref={setSectionRef}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Seo {...SeoProps} />
        <main className={classes.main} style={{ paddingTop: useSpecialLandingPageStyle && 100 }}>
          <Header {...HeaderProps} useSpecialLandingPageStyle={useSpecialLandingPageStyle} itemRef={itemRef}/>
          {children}
          <Footer useSpecialLandingPageStyle={useSpecialLandingPageStyle} />
        </main>
      </ThemeProvider>
    </div>
  );
};

Layout.propTypes = {
  HeaderProps: PropTypes.shape({
    path: PropTypes.string,
    inverseHeader: PropTypes.bool,
  }),
  SeoProps: PropTypes.shape({
    title: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Layout;
