import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import cx from "classnames";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "100%",
    height: "auto",
  },
}));

const ResponsiveImg = forwardRef(({ className, alt, ...rest }, ref) => {
  const classes = useStyles();
  return (
    <img
      ref={ref}
      className={cx(classes.root, className)}
      alt={alt || "Clip"}
      {...rest}
    />
  );
});

ResponsiveImg.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default ResponsiveImg;
