import React, { useEffect, useRef, useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Link as MuiLink,
  Button as MuiButton,
  Divider as MuiDivider, useMediaQuery,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import cx from "classnames";

import LogoLink from "../shared/logo-link";
import AppleIcon from "../../assets/svgs/apple.svg";
import GoogleIcon from "../../assets/svgs/google.svg";
import CustomButton from "../shared/custom-button";
import SquaresFooter from "../../assets/svgs/features/checkerboard/checkerboard_Footer";
import theme from "../../utils/theme";
import Typed from "react-typed";
import Subtitle from "../shared/subtitle";
import { LINKS_SECTION_1, LINKS_SECTION_2, LINKS_SECTION_3, LINKS_SECTION_4, LINKS_SECTION_5, TYPED } from "../../constants/footer";
import { LINKS } from "../../constants/links";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    position: "relative",
    marginTop: 200,

    [theme.breakpoints.down(694)]: {
      marginTop: 260,
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 200,
    },
    [theme.breakpoints.down(553)]: {
      marginTop: 230,
    },
    [theme.breakpoints.down(455)]: {
      marginTop: 250,
    },
    [theme.breakpoints.down(395)]: {
      marginTop: 290,
    },
    [theme.breakpoints.down(379)]: {
      marginTop: 320,
    },

    padding: `0 ${theme.spacing(3)}px`,
    background: `linear-gradient(to bottom, ${theme.colors.blueGradientDark},${theme.colors.blueGradientLight} 30%, ${theme.colors.blueGradientLight})`,
    '@media print': {
      display: 'none',
      height: 0,
    },
  },
  footerSquaresContainer: {
    position: "absolute",
    bottom: 338.6,
    width: '100%',
    left: 0,
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      bottom: 478.6,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 551.6,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 575.6,
    },
  },
  footerSquares: {
    height: 300,
    maxHeight: 300,
    width: 20000,
    display: 'flex',
    overflow: 'hidden',
  },
  footerInner: {
    maxWidth: theme.constants.maxWidth,
    width: '100%',
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  sectionTitle: {
    fontSize: 12,
    textTransform: "uppercase",
    letterSpacing: theme.constants.letterSpacing,
  },
  copyright: {
    fontSize: 12,
    marginTop: 23,
  },
  navLinkWrapper: {
    marginBottom: theme.spacing(0.5),
  },
  linksContainer: {
    justifyContent: "space-between",
  },
  navLink: {
    borderBottom: "1px solid transparent",
    "&:hover": {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  storeButtonContainer:{
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  storeButton: {
    border: "none",
    borderRadius: 0,
    padding: "1px",
    height: 42,
  },
  googleStoreButtonIcon: {
    height: 42,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    height: 1,
    opacity: 0.2,
  },
  opacity: {
    opacity: theme.constants.opacity,
  },
  infoContainer: {
    maxWidth: '100%',
  },
  bottomContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 25,
  },
  loginBottomContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      display: 'flex',
      marginBottom: 40,
    },
  },
  linksSection: {
    paddingLeft: '10%',
    justifyContent: "space-between",

    [theme.breakpoints.down(1024)]: {
      paddingLeft: '5%',
    },

  },
  linksSubSection: {
    maxWidth: 'fit-content',

    [theme.breakpoints.down(1024)]: {
      maxWidth: '100%',
    },
  },
  fakeLink: {
    height: 0,
    width: 80,
  },
  actionBox: {
    maxWidth: 960,
    borderRadius: theme.shape.borderRadius,
    background: `linear-gradient(to bottom, ${theme.colors.orange}, ${theme.colors.orangeGradientDark})`,
    width: '100%',
  },
  footerTyping: {
    width: '100%',
    padding: '0 24px',
    position: "absolute",
    bottom: 329.6,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      bottom: 445.6,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 500.6,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 580.6,
    },
  },
  actionBoxSubtitle: {
    fontSize: 16,
    padding: '0 25px',
    [theme.breakpoints.down('xs')]: {
      fontSize: "0.875rem",
    },
  },
  typedWrapperContainer: {
    padding: '0 30px',
  },
  typedTitle: {
    fontSize: '3.125rem',
    marginBottom: 10,

    [theme.breakpoints.down(1024)]: {
      fontSize: '2.8846rem',
    },

    [theme.breakpoints.down(840)]: {
      fontSize: '2.6442rem',
    },

    [theme.breakpoints.down(600)]: {
      fontSize: '2.06rem',
    },
  },
  typedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  typedWrapper: {
    marginBottom: 10,
    fontSize: "4rem",
    fontFamily: `'Permanent Marker', cursive`,
    color: theme.palette.secondary.main,
    position: "relative",
    bottom: 7,
    lineHeight: 1,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    width: 372,

    [theme.breakpoints.down(731)]: {
      display: 'flex',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: "3rem",
      width: 275,
    },
  },
  buttonDemo: {
    [theme.breakpoints.down(395)]: {
      marginBottom: 15,
    },
  }
}));

const Footer = ({ useSpecialLandingPageStyle = false }) => {
  const classes = useStyles();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const typedRef = useRef(null);
  const [timer, setTimer] = useState(0);

  const onStringTyped = () => {
    if(timer > 15) {
      typedRef.current.stop();
    }
  }

  useEffect(() => {
    let _timer = 0;
    setInterval(() => {
      setTimer(_timer + 1);
      _timer += 1;
    },1000);

    window.addEventListener('scroll', () => {
      _timer = 0;
      handleScroll();
    });
    return window.removeEventListener('scroll', () => {
      clearInterval();
      handleScroll();
    });
  }, []);


  useEffect(() => {
    if(timer === 0 && typedRef.current.pause.status) {
      typedRef.current.start();
    }
  }, [timer]);

  const handleScroll = () => {
    setTimer(0);
  }

  const rendererLinks = (links) =>
    links.map(({ title, to }) => (
      <div className={classes.navLinkWrapper} key={title}>
        <MuiLink
          className={classes.navLink}
          color="secondary"
          underline="none"
          component={to.startsWith('/') ? Link : 'a'}
          to={to.startsWith('/') ? to : ''}
          href={!to.startsWith('/') ? to : ''}
        >
          {title}
        </MuiLink>
      </div>
    ));

  const {
    site: {
      siteMetadata: { appleStore, googleStore },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            appleStore
            googleStore
            description
          }
        }
      }
    `,
  );

  const AllRightsRender = () => <Typography
      color="secondary"
      className={cx(classes.copyright, classes.opacity)}
      mt={5}
    >
      &copy; {new Date().getFullYear()} The Slate Pages, LLC. All rights
      reserved.
  </Typography>

  return (
    <footer className={classes.footer}>
      <div className={classes.footerSquaresContainer}>
        <div className={classes.footerSquares}>
          <SquaresFooter/>
          <SquaresFooter/>
          <SquaresFooter/>
        </div>
      </div>

      <div className={classes.footerTyping}>
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.actionBox}
            pt={4}
            pb={5}
        >
          <Grid container alignItems="center" justify="center" mb={3}
                className={classes.typedWrapperContainer}
          >
            <Typography color="secondary" variant="h2" mr={1} className={classes.typedTitle}>
              Slate your
            </Typography>
            <div className={classes.typedContainer}>
              <div className={classes.typedWrapper}>
                <Typed
                  typedRef={(typed) => {
                    typedRef.current = typed;
                  }}
                  strings={TYPED}
                  typeSpeed={70}
                  backSpeed={50}
                  backDelay={1500}
                  onStringTyped={onStringTyped}
                  // smartBackspace={true}
                  loop
                />
              </div>
              <Typography color="secondary" variant="h2" ml={0.5}>
                .
              </Typography>
            </div>
          </Grid>
          <Subtitle color="secondary" className={classes.actionBoxSubtitle}>
            Get started with your Slate Pages account in less than 5 minutes.
          </Subtitle>
          <Subtitle
              color="secondary"
              className={classes.actionBoxSubtitle}
              mb={3}
          >
            Order online, request a demo, or contact us to learn more.
          </Subtitle>
          <Grid container justify="center">
            <MuiLink
              component={Link}
              className={classes.buttonDemo}
              color="secondary"
              underline="none"
              to={LINKS.contact + '?reason=request_a_demo&subject=Request+a+Slate+Pages+Demo'}
            >
              <CustomButton className={classes.buttonDemo} variant="contained"
                            color={useSpecialLandingPageStyle ? "secondary" : "primary"} mx={1}
                            state={{ prevPath: 'demo' }}
              >
                Request a Demo
              </CustomButton>
            </MuiLink>
            <MuiLink
              component={Link}
              className={classes.buttonDemo}
              color="secondary"
              underline="none"
              to={LINKS.contact}
            >
              <CustomButton variant="contained" color="secondary" mx={1}>
                Contact Us
              </CustomButton>
            </MuiLink>
          </Grid>
        </Grid>
      </div>


      <Grid mx="auto" pt={10} pb={6} className={classes.footerInner}>
          <div className={classes.logoContainer}>
            <LogoLink inverted />
            <div className={classes.storeButtonContainer}>
              <Button
                variant="outlined"
                href={appleStore}
                className={classes.storeButton}
              >
                <AppleIcon />
              </Button>
              <Button
                variant="outlined"
                href={googleStore}
                className={classes.storeButton}
                ml={2}
              >
                <GoogleIcon className={classes.googleStoreButtonIcon} />
              </Button>
            </div>
          </div>
        <Divider component="div" className={classes.divider} my={5} />

        <Grid container className={classes.bottomContainer}>
          <Grid item xs={12} sm={12} md={12} lg={4} className={classes.loginBottomContainer}>
            <CustomButton
              variant="contained"
              color={ useSpecialLandingPageStyle ? "secondary" : "primary"}
              href={process.env.GATSBY_DASHBOARD_LOGIN}
            >
              Login to Dashboard
            </CustomButton>
            {!isMdScreen && <AllRightsRender/>}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={4} container className={classes.linksSection}>
            <Grid item xs={12} md={12} className={classes.linksContainer}>
              <Typography
                color="secondary"
                className={cx(classes.sectionTitle, classes.opacity)}
                mb={1}
              >
                Learn More
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.linksSubSection}>
              {rendererLinks(LINKS_SECTION_1)}
            </Grid>
            <Grid item xs={12} md={6} className={classes.linksSubSection}>
              {rendererLinks(LINKS_SECTION_2)}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={4} container className={classes.linksSection}>
            <Grid item xs={12} md={12}>
              <Typography
                color="secondary"
                className={cx(classes.sectionTitle, classes.opacity)}
                mb={1}
              >
                Company
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.linksSubSection}>
              {rendererLinks(LINKS_SECTION_3)}
            </Grid>
            <Grid item xs={12} md={6} className={classes.linksSubSection}>
              {rendererLinks(LINKS_SECTION_4)}
            </Grid>
            <Grid item xs={12} md={6} className={classes.linksSubSection}>
              {/*added empty space for correct placing Section 5*/}
              <div className={classes.fakeLink}/>
            </Grid>
            <Grid item xs={12} md={6} className={classes.linksSubSection}>
              {rendererLinks(LINKS_SECTION_5)}
            </Grid>
          </Grid>
          { isMdScreen && <AllRightsRender />  }
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
