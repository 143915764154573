import { LINKS } from "./links";


export const LINKS_SECTION_1 = [
  {
    title: "Solutions",
    to: LINKS.solutions,
  },
  {
    title: "Features",
    to: LINKS.features,
  },
];

export const LINKS_SECTION_2 = [
  {
    title: "Pricing",
    to: LINKS.pricing,
  },
];

export const LINKS_SECTION_3 = [
  {
    title: "Contact Us",
    to: LINKS.contact,
  },
  {
    title: "Support",
    to: LINKS.support,
  },
];

export const LINKS_SECTION_4 = [
  {
    title: "Terms of Use",
    to: LINKS.terms,
  },
  {
    title: "Privacy Policy",
    to: LINKS.privacy,
  },
];

export const LINKS_SECTION_5 = [
  {
    title: "Refund Policy",
    to: LINKS.refundPolicy,
  },
];

export const TYPED = ["automation", "products", "industry"];