import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";

import LogoLink from "../shared/logo-link";
import CustomButton from "../shared/custom-button";
import SideMenu from "./header-mobile-layout-side-menu";
import TopMenu from "./header-desktop-layout-top-menu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    padding: theme.spacing(1, 0, 1),
    maxHeight: 100,
    height: 100,
    background: "rgba(255, 255, 255, 0.9)",
    width: '100%',
    maxWidth: '100vw',
    left: 0,
    '@media print': {
      display: 'none',
    },
  },
  logoWrapper: {
    marginRight: theme.spacing(3),
  },
  buttonsWrapper: {
    marginLeft: "auto",
  },
  leftSpacing: {
    fontWeight: 600,
    marginLeft: 28,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  toolbar: {
    padding: '0 30px',
    height: '100%',
    minHeight: '100%',
  },
  touchRemove: {
    touchAction: 'none',
    msTouchAction: "none",
    "-webkit-overflow-scrolling": "touch",
  },
  link: {
    [theme.breakpoints.down(1024)]: {
      fontSize: 42,
    },
  },
}))

const Header = ({ position = "fixed", className, path, useSpecialLandingPageStyle = false, itemRef}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (isOpen){
      document.documentElement.classList.add(classes.touchRemove)
      document.getElementById('___gatsby').classList.add(classes.touchRemove)
      document.body.classList.add(classes.touchRemove)
    } else {
      document.documentElement.classList.remove(classes.touchRemove)
      document.getElementById('___gatsby').classList.remove(classes.touchRemove)
      document.body.classList.remove(classes.touchRemove)
    }
  }, [isOpen]);

  const setItemRef = useCallback((node) => {
    itemRef.current = node;
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <AppBar
      color="transparent"
      className={cx(classes.appBar, className)}
      position={position}
      ref={setItemRef}
    >
      <Toolbar classes={{
        root: classes.toolbar,
      }}>
        <div className={classes.logoWrapper}>
          <LogoLink linkClassName={classes.link}/>
        </div>
        {isLgScreen && <TopMenu path={path}/>}
        <div className={classes.buttonsWrapper}>
          {isLgScreen ? (
            <>
              <CustomButton
                variant="contained"
                color={ useSpecialLandingPageStyle ? "secondary" : "primary" }
                href={process.env.GATSBY_DASHBOARD_LOGIN}
                ml={1}
                className={classes.leftSpacing}
              >
                Login
              </CustomButton>
            </>
          ) : (
            <SideMenu
              toggleDrawer={toggleDrawer}
              isOpen={isOpen}
            />
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  position: PropTypes.string,
  className: PropTypes.string,
  path: PropTypes.string,
};

export default Header;
