import React  from "react";
import PropTypes from "prop-types";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  IconButton,
  SwipeableDrawer,
  Grid as MuiGrid,
  Button as MuiButton, Link as MuiLink,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import CustomButton from "../shared/custom-button";
import Icon from "@material-ui/core/Icon";
import MenuItem from "../../assets/images/Menu@3x.svg"
import AppleIcon from "../../assets/svgs/apple.svg";
import GoogleIcon from "../../assets/svgs/google.svg";
import Typography from "@material-ui/core/Typography";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from "../../utils/theme";
import { NAV_LINKS_SIDE_MENU } from "../../constants/navigation";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    '&:focus > $content, &$selected > $content': {
      backgroundColor: 'transparent',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
    "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent"
    },
    "&$selected > $content $label": {
      backgroundColor: "transparent"
    },
    "&$selected > $content": {
      backgroundColor: "transparent"
    },
    '&$selected > $content $label:hover, &$selected:focus > $content $label': {
      backgroundColor: "transparent",
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      lineHeight: 'var(--tree-view-line-height)',
      paddingLeft: 30,
      opacity: 'var(--tree-view-opacity)',
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'var(--tree-view-font-weight)',
    flexGrow: 1,
    color: 'white',
    fontSize: 'var(--tree-view-font-size)',
    lineHeight: 'var(--tree-view-line-height)',
    cursor: 'var(----tree-view-cursor)',
  },
  iconContainer: {
    position: "absolute",
    right: 30,
  },

}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, to, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {props.children || !to ?
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            :
            <MuiLink
              key={to}
              component={to.startsWith('/') ? Link : 'a'}
              to={to.startsWith('/') ? to : ''}
              href={!to.startsWith('/') ? to : ''}
              className={classes.labelText}
              underline="none"
            >
              {labelText}
            </MuiLink>
          }
        </div>
      }
      style={{
        '--tree-view-opacity': to ? 0.5 : 1,
        '--tree-view-line-height': props.nodeId >= 100 ? 1.2 : 2.5,
        '--tree-view-font-weight': props.nodeId >= 100 ? 400 : 500,
        '--tree-view-font-size': props.nodeId >= 100 ? '1rem' : '1.25rem',
        '--tree-view-cursor': props.nodeId > 100 ? 'inherit' : 'pointer',
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100%',
    flexShrink: 0,
    padding: 40,
    height: "auto"
  },
  sideMenuContainer: {
    background: 'linear-gradient(0deg, rgba(0,30,55,1) 1%, rgba(0,17,28,1) 100%)',
  },
  rightMenuContainer: {
    minWidth: 330,
    width: '30vw',
    minHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerText: {
    color: theme.palette.secondary.main,
  },
  drawerTextSecondary: {
    opacity: 0.5,
  },
  drawerListItem: {
    padding: theme.spacing(1, 1.5),
  },
  menuIcon: {
    width: 23,
    height: 23,
  },
  toolbar: {
    padding: '0 30px',
    height: '100%'
  },
  horizontalLine: {
    margin: '0px 10%',
    padding: '40px 0',
    width: '80%',
    borderTop: '1px solid rgb(33 61 83)',
    borderBottom: '1px solid rgb(33 61 83)',
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
    minHeight: 154,
  },
  title: {
    fontWeight: 400,
    fontSize: '1.25rem',
    opacity: .5,
    marginBottom: 10.
  },
  leftMeuLogin: {
    margin: 0,
    width: '100%',
  },
  storeButton: {
    border: "none",
    borderRadius: 0,
    padding: "1px",
    margin: 0,
    height: 42,
  },
  googleStoreButtonIcon: {
    height: 42,
  },
  buttonGroup: {
    marginTop: 40,
    minHeight: 82,
    height: 82,
    marginBottom: 40,
    paddingBottom: 0,
    overflow: "hidden"
  },
  storeButtonContainer: {
    display: "flex",
    padding: '0px 26px',
    justifyContent: "space-between",
  },
}));

const SideMenu = ({isOpen, toggleDrawer}) => {
  const classes = useStyles();

  const {
    site: {
      siteMetadata: { appleStore, googleStore },
    },

  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            appleStore
            googleStore
            description
          }
        }
      }
    `,
  );

  const iconStyle = { fontSize: '2rem', color: theme.palette.common.white, opacity: '50%' };

  return (
    <div>
      <IconButton
        aria-label="menu-header"
        aria-controls="menu-header"
        aria-haspopup="true"
        onClick={toggleDrawer(true)}
      >
        <Icon classes={{
          root: classes.menuIcon,
        }}>
          <img alt="edit" src={MenuItem} />
        </Icon>
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paperAnchorRight: classes.sideMenuContainer
        }}
      >
        <div className={classes.rightMenuContainer}>
          <Grid
            container
            direction="column"
            className={classes.drawer}
            justify="space-between"
            p={4}
          >
            <TreeView
              className={classes.root}
              defaultExpanded={['5']}
              defaultCollapseIcon={<ExpandMoreIcon style={iconStyle}/>}
              defaultExpandIcon={<ChevronRightIcon style={iconStyle}/>}
              defaultEndIcon={<div style={{ width: 24 }} />}
            >
              {NAV_LINKS_SIDE_MENU.map(({ label, to, nodes }, index) => (
                <StyledTreeItem
                  nodeId={(index).toString()}
                  labelText={label}
                  to={ to }
                  key={label}
                >
                  {
                    nodes && nodes.map(({ label, to }, i) =>
                      <StyledTreeItem
                        nodeId={(index + 100 + i).toString()}
                        labelText={ label }
                        to={ to }
                        key={label}
                      />
                    )
                  }
                </StyledTreeItem>
              ))}
            </TreeView>
          </Grid>
          <div>
            <div className={classes.horizontalLine}>
              <Typography color="secondary" className={classes.title} mb={1}>
                Already use Slate Pages?
              </Typography>
              <CustomButton
                variant="contained"
                color="primary"
                href={process.env.GATSBY_DASHBOARD_LOGIN}
                ml={1}
                className={classes.leftMeuLogin}
              >
                login to dashboard
              </CustomButton>
            </div>
            <div className={classes.buttonGroup}>
              <div className={classes.storeButtonContainer}>
                <Button
                  variant="outlined"
                  href={appleStore}
                  className={classes.storeButton}
                >
                  <AppleIcon />
                </Button>
                <Button
                  variant="outlined"
                  href={googleStore}
                  className={classes.storeButton}
                  ml={2}
                >
                  <GoogleIcon className={classes.googleStoreButtonIcon} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

SideMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default SideMenu;
