import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core";
import cx from "classnames";

import ResponsiveImg from "./responsive-img";
import Logo from "../../assets/images/logo-full-grey.png";
import LogoInvert from "../../assets/images/logo-full-white.png";
import { LINKS } from "../../constants/links";

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 180,
    // maxHeight: 40,
    // verticalAlign: "middle",
  },
}));

const LogoLink = ({ inverted = false, className, linkClassName }) => {
  const classes = useStyles();
  return (
    <Link to={LINKS.root} className={cx(linkClassName)}>
      <ResponsiveImg
        src={inverted ? LogoInvert : Logo}
        alt="Slate Pages"
        className={cx(classes.logo, className)}
      />
    </Link>
  );
};

LogoLink.propTypes = {
  inverted: PropTypes.bool,
  className: PropTypes.string,
};

export default LogoLink;
