export const LINKS = {
  root: '/',
  solutions: '/solutions/',
  propertyManagement: '/solutions/industries/property-management/',
  construction: '/solutions/industries/construction/',
  mechanicalElectricalPlumbing: '/solutions/industries/mechanical-electrical-plumbing/',
  manufacturingOem: '/solutions/industries/manufacturing-oem/',
  emergencyServices: '/solutions/industries/emergency-services/',
  otherIndustries: '/solutions/industries/other-industries/',
  portableToilets: '/solutions/industries/portable-toilets/',
  qrCodeAssetTracking: '/solutions/use-cases/qr-code-asset-tracking/',
  digitisationOfDocuments: '/solutions/use-cases/digitization-of-documents/',
  equipmentMaintenance: '/solutions/use-cases/equipment-maintenance/',
  dailyHealthChecks: '/solutions/use-cases/daily-health-checks/',
  routineInspections: '/solutions/use-cases/routine-inspections/',
  otherUseCases: '/solutions/use-cases/other-use-cases/',
  inventoryManagement: '/solutions/use-cases/inventory-management/',
  features: '/features/',
  design: '/features/design-and-customize/',
  capture: '/features/capture-and-store/',
  viewAndReport: '/features/report-and-analyze/',
  pricing: '/pricing/',
  resources: '/resources/',
  tags: '/tags/',
  blog: '/resources/blog/',
  blogArticle: '/resources/blog/article',
  caseStudies: '/resources/case-studies/',
  caseStudiesArticle: '/resources/case-studies/article',
  support: 'https://support.slatepages.com/',
  cleanSlate: '/clean-slate/',
  facilities: '/facilities/',
  water: '/water/',
  general: '/general/',
  markets: '/markets/',
  slateFeatures: '/slate-features/',
  contact: '/contact-us/',
  privacy: '/privacy/',
  terms: '/terms/',
  refundPolicy: '/refund-policy/',
}