import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0c64ce",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#0c64ce",
    },
    text: {
      primary: "#000000",
      secondary: "#5d5d5d",
    },
    background: {
      default: "#FFFFFF",
      lightGrey: '#f4f4f4',
    },
  },
  typography: {
    fontFamily: `"Roboto", sans-serif`,
    h2: {
      fontWeight: 700,
      fontSize: "2.6rem",
      lineHeight: 1.04,
      color: "#0b365d",
    },
    h4: {
      fontWeight: 700,
      fontSize: '2.2rem',
      lineHeight: 1.056,
      color: "#0b365d",
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.8rem',
      lineHeight: 1.056,
      color: "#0b365d",
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '1.4rem',
      lineHeight: 1.056,
      color: "#0c64ce",
    },
    subtitle2: {
      fontWeight: 1000,
      fontSize: '1.2rem',
      lineHeight: 1.056,
      fontFamily: `"Georgia", sans-serif`,
      color: "#949494",
      fontStyle: 'italic',
    },
    body1: {
      fontSize: '1rem',
      color: "#5d5d5d",
    }
  },
  shape: {
    borderRadius: 8,
  },
  colors: {
    orange: "#f15a29",
    orangeGradientDark: "#d13401",
    greyShadow: "#a3a5a3",
    blueGradientExtraLight: "#002F58",
    blueGradientMedium: "#001834",
    blueGradientLight: "#011e38",
    blueGradientDark: "#00111c",
    black: "#000000",
  },
  constants: {
    maxWidth: 960,
    letterSpacing: "2px",
    opacity: 0.5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1024,
      xl: 1920,
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      // outlined: {
      //   "&.MuiSelect-outlined": {
      //     fontSize: '1rem',
      //     lineHeight: '1.188',
      //     color: '#01172A',
      //     opacity: .5,
      //     paddingLeft: 10,
      //   }
      // }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#f15a29',
          borderWidth: 1,
        },
      }
    },
  },
});

export default responsiveFontSizes(theme);
